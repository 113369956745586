<template>
  <depreciation-panels
    :canDelete="canDelete"
    :items="value.depreciacoes"
    :projectsByYear="projectsByYear"
    :valid.sync="valid.depreciacoes"
    @input="inputHandler('depreciacoes', $event)"
    @loadProjectsFromYear="$emit('loadProjectsFromYear', $event)"
  >
    <input-v
      v-for="(field, key) in fields"
      class="pl-4"
      :class="{ readonly: field.readonly }"
      :colSize="field.colSize"
      :editable="field.editable"
      :key="key"
      :label="field"
      :opts="opts"
      :valid.sync="valid[field.key]"
      :value="value[field.key]"
      @input="inputHandler(field.key, $event)"
    ></input-v>
  </depreciation-panels>
</template>

<script>
export default {
  components: {
    "depreciation-panels": () => import("./depreciation.panels.vue"),
    "input-v": () => import("@/components/input-v.vue"),
  },
  computed: {
    fields: function () {
      const all = [
        {
          key: "descricao",
          name: "Descrição do Item",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "finalidade",
          name: "Classificação LI",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "finalidades", key: "value", name: "value" },
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor do Item",
          type: this.$fieldTypes.MONEY,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "periodo_depreciacao",
          name: "Período de Utilização (em meses)",
          type: this.$fieldTypes.NUMBER,
          colSize: 3,
          rules: [
            { rule: "required" },
            { rule: "min", params: { size: 1 } },
          ],
        },
        {
          key: "valor_deprec",
          name: "Valor Depreciado",
          type: this.$fieldTypes.MONEY,
          editable: false,
          readonly: true,
          colSize: 3,
          hide: !this.showValorDeprec,
        },
      ];

      return all.filter(({ hide }) => !hide);
    },
  },
  created: function () {
    this.$emit('update:valid', this.generateUpdateValid());
  },
  data: function () {
    return {
      opts: {
        finalidades: [
          { value: "EQUIPAMENTOS" },
          { value: "SOFTWARE (COMPRA)" },
          { value: "SOFTWARE (LICENÇA DE USO)" },
        ],
      },
      showValorDeprec: true,
      timeoutId: null,
      valid: {
        depreciacoes: null,
        descricao: null,
        finalidade: null,
        valor: null,
        valor_deprec: null,
      },
    };
  },
  methods: {
    generateUpdateValid: function () {
      const self = this;
      return () => {
        const depreciacoesFn = self.valid.depreciacoes;
        const numDepreciacoesErradas = typeof depreciacoesFn === 'function' ? depreciacoesFn() : 0;
        const campos = Object
          .entries(self.valid)
          .filter(([key]) => key !== 'depreciacoes')
          .reduce((acc, [key, fn]) => {
            if (fn && fn()) {
              return acc;
            }

            const { name } = self.fields.find((field) => field.key === key);
            return [ ...acc, name ];
          }, []);
        return numDepreciacoesErradas === 0 ? campos : campos.concat([`${numDepreciacoesErradas} depreciações erradas`])
      }
    },
    inputHandler: function (key, value) {
      this.$emit('input', {
        ...this.value,
        [key]: value,
      });
    },
    updateValorDeprec: function () {
      let { periodo_depreciacao, valor } = this.value;
      const periodoDepreciacao = parseFloat(periodo_depreciacao, 10);
      const valorItem = parseFloat(valor, 10);
      this.value.valor_deprec = (periodo_depreciacao > 1 && valor > 0) ? valorItem / periodoDepreciacao : 0;
      // Como o input-v não atualiza programaticamente após ser renderizado, o truque é forçar ele a re-renderizar de novo
      clearTimeout(this.timeoutId);
      this.showValorDeprec = false;
      this.timeoutId = setTimeout(() => this.showValorDeprec = true, 50);      
    },
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    projectsByYear: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({
        depreciacoes: [],
        descricao: null,
        periodo_depreciacao: null,
        valor: null,
        valor_deprec: null,
      }),
    }
  },
  watch: {
    'value.periodo_depreciacao': function () {
      this.updateValorDeprec();
    },
    'value.valor': function () {
      this.updateValorDeprec();
    },
  },
}
</script>

<style lang="scss" scoped>
.readonly ::v-deep .v-input__slot {
  background-color: #e9ecef;
  border-color: #6c757d;

  &, & input {
    cursor: default;
  }
}
</style>
